.dur, .price {
    display: block;
    padding-left: 25px;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    color: #aaa;
    font-size: 14px;
    line-height: 20px;
}

.dur {
    background-image: url(./../assets/images/clock.png);
}

.price {
    background-image: url(./../assets/images/tenge.png);
}