@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);

.facility:first-child {
  margin-top: 20px;
}

.facility {
  display: block;
  padding-left: 60px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 40px;
  color: #666666;
  background-size: 40px 40px;
}


.montserrat {  
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.vum-page p {
  font-size: 22px;
   color: #000;
}

.vum-page img {
  max-width: 100%;
}

ul.vum-want {
  padding: 0;
}

ul.vum-want > li {
  color: #2786ff;
  font-size: 22px;
  margin-bottom: 20px;
  background-image: url(./../assets/images/icon_arrow.png);
  background-position: left;
  background-repeat: no-repeat;
  display: block;  
  padding-left: 40px;
}

.vum-contacts p {
  text-align: center;
  color: #017cc2;
}