@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);

.facility:first-child {
  margin-top: 20px;
}

.facility {
  display: block;
  padding-left: 60px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 40px;
  color: #666666;
  background-size: 40px 40px;
}


.montserrat {  
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.freecourse-page {
  max-width: 100%;
}

.freecourse-page p {
  font-size: 22px;
  color: #000;
  line-height: normal;
}

.freecourse-page img {
  max-width: 100%;
}

.freecourse-page .section-title {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
}

ul.fc-list-1 {
  padding: 0;
}

ul.fc-list-1 > li {
  padding: 0;
  font-size: 22px;
  color: #000;
  margin-bottom: 20px;
}

ul.animation-list {
  padding: 0;
}

ul.animation-list > li {
  padding: 0;
  font-size: 22px;
  color: #000;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.freecourse-contacts p {
  text-align: center;
  color: #017cc2;
}

ul.freecourse_attr {
  padding: 0;
}

ul.freecourse_attr li {
  font-size: 20px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px auto;
  margin-bottom: 10px;
  padding-left: 40px;
}

ul.freecourse_attr li span {
  color: #ff8035;
  font-weight: bold;
}